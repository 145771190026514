import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Success = () => (
  <Layout location={typeof window !== `undefined` ? location : null}>
    <SEO
      title="Success"
      description="Success confirmation - Cinelíngua francesa"
      pathname="/success/"
    />
    <div className="successPage">
      <header className="masthead">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-7 my-auto">
              <div className="header-content mx-auto">
                <h1 className="mb-3">Votre demande a été envoyée !</h1>
                <p className="lead mb-5">Nous reviendrons rapidement vers vous !</p>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  </Layout>
);

export default Success;
